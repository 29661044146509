
// Primary-colors
// $primary-color:  #22b0fc; //color for: Page = 1
// $primary-color-p3:  #0f53fa; //color for: Page = 3
// $primary-color-p4:  #5473E8; //color for: Page = 4
// $primary-color-p5:  #10d396; //color for: Page = 5

// Secondary-colors
// $secondary-color: #f77321; 
// $secondary-color-p3: #ffd166; //color for: Page = 3
// $secondary-color-p4: #36c0cd; //color for: Page = 4




// Landing1 Fonts and colors
$l1-primary-font: 'CircularStd',sans-serif;
$l1-primary-heading: #19191b;
$l1-primary-text: #696871;
$l1-primary-color: #f04037;
$l1-secondary-color: #5454d4;

// Landing2 Fonts and colors
$l2-primary-font: 'CircularStd',sans-serif;
$l2-primary-heading: #19191b;
$l2-primary-text: #696871;
$l2-primary-color: #f04037;
$l2-secondary-color: #413e65;
// $l1-border: #f04037;
$l5-primary-color:#f0544c;
$l5-primary-border-color:#c31a12;

$theme-primary-font: 'CircularStd',sans-serif;
$theme-primary-heading: #19191b;
$theme-primary-text: #696871;
$theme-primary-color: #f04037;
$theme-secondary-color: #5454d4;

$theme-red-color: #f04037;
$theme-blue-color: #5454d4;
$theme-green-color: #77bf41;
$theme-yellow-color: #fedc5a;
$theme-ash-color: #413e65;
$theme-purple-color: #a665fb;



// Screen Width
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1366px;
$screen-xxxl: 1600px;

.bg-whisper{
  background: #F7F7FB;
}